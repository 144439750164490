<template>
  <section class="section section__documents documents">
    <div class="">
      <ul v-if="documents_page.data && documents_page.data.length" class="documents__list">
        <li class="documents__titles">
          <div class="documents__title document__number">Номер документа</div>
          <div class="documents__title document__title">Название</div>
          <div class="documents__title document__date">Дата документа</div>
        </li>
        <li v-for="(item, index) in documents_page.data" :key="index">
          <a @click.prevent="showDocumentModal(item)" class="documents__item" href="#">
            <div v-if="item.document && item.document.number" class="document__number">
              <span>№</span> {{ item.document.number }}
            </div>
            <div v-if="item.document.title" class="documents__item-title">
              {{ item.document.title }}
            </div>
            <div class="document__date" v-if="item.document_date">
              <time :datetime="item.document_date | robotDate">{{ item.document_date | formatDate }}</time>
            </div>
          </a>
        </li>
      </ul>
      <p v-else-if="!$store.state.filterResponse" class="_item__text">Документов нет</p>
      <p v-else-if="$store.state.filterData.get('title')" class="_item__text">
        По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
      </p>
      <p v-else class="_item__text">По вашему запросу ничего не найдено</p>
    </div>

    <a v-if="loading" href="#" class="show-more-link">
      <LoadingIndicator title="Загрузка" />
    </a>
    <a
      @click.prevent="submitFilterForm"
      v-else-if="$store.state.filterResponse && $store.state.filterResponse.next_page_url"
      href="#"
      class="show-more-link"
    >
      Показать ещё
    </a>
    <a @click.prevent="loadMore" v-else-if="documents_page.next_page_url" href="#" class="show-more-link">
      Показать ещё
    </a>
  </section>
</template>

<script>
import axios from "axios";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import DocumentModal from "./modals/DocumentModal.vue";

export default {
  name: "DocumentsComponent",
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    documents_page() {
      if (this.$store.state.filterResponse) {
        return this.$store.state.filterResponse;
      }
      return this.$store.state.documents_page;
    },
  },
  methods: {
    showDocumentModal(doc) {
      this.$store.state._modals.push({
        component: DocumentModal,
        options: {
          document: doc,
        },
      });
    },
    /**
     * Загрузить еще документов
     * @returns {Promise<void>}
     */
    async loadMore() {
      if (!this.loading) {
        this.loading = true;
        await axios
          .get(this.documents_page.next_page_url)
          .then(({ data }) => {
            this.loading = false;
            // добавляем к существующим новые документы с новой страницы пагинации
            this.$store.state.documents_page.data = this.$store.state.documents_page.data.concat(
              data.documents.data
            );
            this.$store.state.documents_page.next_page_url = data.documents.next_page_url;
          })
          .catch(({ response }) => {
            this.loading = false;
            console.error(response?.data?.message);
          });
      }
    },
    /**
     * Запрос для получения следующей страницы пагинации
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        await this.$store
          .dispatch("POST_DOCUMENTS_FILTER", {
            data: this.$store.state.filterData,
            url: this.$store.state.filterResponse.next_page_url,
          })
          .then((response) => {
            this.loading = false;
            const oldResponse = this.$store.state.filterResponse;
            this.$store.state.filterResponse = response.data.documents;
            this.$store.state.filterResponse.data = oldResponse.data.concat(
              this.$store.state.filterResponse.data
            );
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
  },
};
</script>
