<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <DocumentsFilter />
        <Documents />
      </div>
    </div>
  </main>
</template>

<script>
import DocumentsFilter from "@/views/documents/components/DocumentsFilter.vue";
import Documents from "@/views/documents/components/Documents.vue";

export default {
  name: "DocumentsPage",
  async asyncData({ store }) {
    await store.dispatch("GET_DOCUMENTS_PAGE");
  },
  components: {
    DocumentsFilter,
    Documents,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.filterData = null;
    this.$store.state.filterResponse = null;
    this.$store.state.filterList = [];
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
<style lang="stylus">
@import "~@/styles/parts/documents.styl"
</style>
